import React from "react";
import styles from "./Navbar.module.scss";
import cn from "utils/classNames";
interface AnchorLinkProps {
  anchor: string;
}

const AnchorLink: React.FC<AnchorLinkProps & React.HTMLAttributes<HTMLLinkElement>> = ({
  anchor,
  children,
  className,
}) => {
  return (
    <a
      className={className}
      onClick={() => {
        document.querySelector(anchor).scrollIntoView({ behavior: "smooth" });
      }}
    >
      {children}
    </a>
  );
};

const Navbar: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  return (
    <div className={cn(styles.navbar, className)}>
      <div className={styles.navbarMenuItemsContainer}>
        <AnchorLink className={styles.navbarMenuItem} anchor="#whatwedo">
          What we do
        </AnchorLink>
        {/* <div className={styles.navbarMenuItem}>
          <a href="#projects">Projects</a>
        </div> */}
        <AnchorLink className={styles.navbarMenuItem} anchor="#team">
          Team
        </AnchorLink>
        <AnchorLink className={styles.navbarMenuItem} anchor="#contact">
          Contact
        </AnchorLink>
        <AnchorLink className={styles.navbarMenuItem} anchor="#questions">
          FAQ
        </AnchorLink>
      </div>
    </div>
  );
};

export default Navbar;
