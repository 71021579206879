import React, { FunctionComponent } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Chip from "components/Chip";
import { Skill } from "sections/Team/Team";
import styles from "./CardTeamMember.module.scss";
import MemberPhoto from "./MemberPhoto";
import CardContainer from "../CardContainer";
import cn from "utils/classNames";
import useViewport from "shared/hooks/useViewport";
import { useCallback } from "react";

type Props = {
  skills: Skill[];
  resume: string;
  photo: string;
  name: string;
  position: string;
  experience: string;
  entryAnimation: { [key: string]: any };
};

const CardTeamMember: FunctionComponent<Props> = ({ skills, name, position, experience, entryAnimation, ...props }) => {
  const { width } = useViewport();
  gsap.registerPlugin(ScrollTrigger);

  const ref = useCallback((node) => {
    if (!node) return;
    const trigger = node.getElementsByClassName("anima");

    gsap.from(trigger, {
      scrollTrigger: {
        trigger: trigger,
        start: width > 860 ? "top 80%" : "top 70%",
      },
      ...entryAnimation,
    });
  }, []);

  return (
    <div ref={ref}>
      <CardContainer className={cn("anima", styles.cardTeamContainer)}>
        <div className={styles.cardTeamMember}>
          <MemberPhoto {...props} />
          <div className={styles.cardTeamMemberName}>{name}</div>
          <div className={styles.cardTeamMemberPosition}>{position}</div>
          <div className={styles.cardTeamMemberExperience}>{experience}</div>
          <div className={styles.cardTeamMemberSkills}>
            {skills.map((skill, index) => (
              <Chip className={styles.skillChip} key={index} background={skill.background} label={skill.name} />
            ))}
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default CardTeamMember;
