import React, { useState } from "react";
import CardContainer from "components/Card/CardContainer";
import EmailIcon from "assets/svgs/email.svg";
import styles from "./ContactForm.module.scss";
import cn from "utils/classNames";
import useViewport from "shared/hooks/useViewport";

var timestampOnComponentLoad = Date.now();

const ContactForm: React.FC = ({ className }) => {

  const { width } = useViewport();

  const smallScreen = () => {
    return width <= 600;
  };

  const defaultState = {
    name: "",
    email: "",
    message: "",
    needs: {
      webApp: false,
      mobileApp: false,
      erpSaas: false,
      other: false,
    },
    internalFormState: {
      emailSendInProgress: false,
      emailAlreadySent: false,
      nameFieldError: false,
      emailFieldError: false,
    },
  };

  const [contactData, setContactData] = useState(defaultState);

  const onInputChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    setContactData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCheckboxClick = (event) => {
    const name = event.target.id;
    setContactData((prevState) => ({
      ...prevState,
      needs: {
        ...prevState.needs,
        [name]: !prevState.needs[name],
      },
    }));
  };

  const setInternalStateProp = (prop, value) => {
    setContactData((prevState) => ({
      ...prevState,
      internalFormState: {
        ...prevState.internalFormState,
        [prop]: value,
      },
    }));
  };

  const validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const getAdditionalInformation = (initialTimestamp: number): { [key: string]: any } => {
    const timeUntilFormSubmitInMs = Date.now() - initialTimestamp;
    const hoursInterval = Math.floor(timeUntilFormSubmitInMs / 1000 / 60 / 60);
    const minutesInterval = Math.floor((timeUntilFormSubmitInMs / 1000 / 60 / 60 - hoursInterval) * 60);
    const secondsInterval = Math.floor(
      ((timeUntilFormSubmitInMs / 1000 / 60 / 60 - hoursInterval) * 60 - minutesInterval) * 60,
    );

    const hours = `${hoursInterval < 10 ? "0" : ""}${hoursInterval}`;
    const minutes = `${minutesInterval < 10 ? "0" : ""}${minutesInterval}`;
    const seconds = `${secondsInterval < 10 ? "0" : ""}${secondsInterval}`;

    return {
      referrer: document?.referrer,
      timeUntilFormSubmit: `${hours}:${minutes}:${seconds}`,
    };
  };

  const sendEmail = async (emailBody: { [key: string]: any }): Promise<{ [key: string]: any }> => {
    const API_URI = "https://mailservice.minervagate.com/api/contact/sendEmail";

    let requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(emailBody),
    };

    return fetch(API_URI, requestPayload);
  };

  const formSubmit = async () => {
    if (contactData.internalFormState.emailSendInProgress) {
      return;
    }

    let error = false;

    if (!contactData.name) {
      error = true;
      setInternalStateProp("nameFieldError", true);
    } else {
      setInternalStateProp("nameFieldError", false);
    }

    if (!validateEmail(contactData.email)) {
      error = true;
      setInternalStateProp("emailFieldError", true);
    } else {
      setInternalStateProp("emailFieldError", false);
    }

    if (error) {
      return;
    }

    const { internalFormState, ...emailBody } = contactData; //get everything but internalFormState

    try {
      setInternalStateProp("emailSendInProgress", true);
      const response = await sendEmail({
        ...emailBody,
        additionalInformation: getAdditionalInformation(timestampOnComponentLoad),
      });
      setInternalStateProp("emailSendInProgress", false);
      setInternalStateProp("emailAlreadySent", true);
    } catch (error) {
      // TODO(emi): treat error case
    }
  };

  return (
    <CardContainer className={cn(styles.formContainer, className)}>
      <div className={styles.formContainerLeft}>
        <div className={styles.formContainerLeftDescription}>
          Fill in the form and we'll reach back to you within 24 hours.
        </div>
        <a href="mailto:contact@minervagate.com" className={styles.formContainerLeftMailContainer}>
          {smallScreen() ? null : <img src={EmailIcon} className={styles.formContainerLeftMailContainerIcon} />}
          <div className={styles.formContainerLeftMailContainerMail}>contact@minervagate.com</div>
        </a>
      </div>
      <div className={styles.formContainerRight}>
        <div className={styles.formContainerRightFields}>
          <div className={styles.formContainerRightFieldsField}>
            <label htmlFor="name-field">Name</label>
            <input
              className={cn(
                styles.input,
                contactData.internalFormState.nameFieldError && styles.inputWithValidationError,
              )}
              type="text"
              id="name"
              required
              autoComplete="off"
              onChange={onInputChange}
              value={contactData.name}
            />
          </div>
          <div className={styles.formContainerRightFieldsField}>
            <label htmlFor="email-field">Your email</label>
            <input
              className={cn(
                styles.input,
                contactData.internalFormState.emailFieldError && styles.inputWithValidationError,
              )}
              type="text"
              id="email"
              required
              autoComplete="off"
              onChange={onInputChange}
              value={contactData.email}
            />
          </div>
        </div>
        <div className={styles.formContainerRightFieldsMargin}>
          <div className={styles.formContainerRightFieldsMarginLabel}>How can we help you?</div>
          <div className={styles.formContainerRightFieldsMarginCheckboxContainer}>
            <label className={styles.labelCheckbox} htmlFor="webApp">
              <input
                className={styles.checkbox}
                type="checkbox"
                id="webApp"
                onChange={onCheckboxClick}
                checked={contactData.needs.webApp}
              />
              <div className={styles.labelText}>Web App</div>
              <span className={styles.checkmark}></span>
            </label>
            <label className={styles.labelCheckbox} htmlFor="mobileApp">
              <input
                className={styles.checkbox}
                type="checkbox"
                id="mobileApp"
                onChange={onCheckboxClick}
                checked={contactData.needs.mobileApp}
              />
              <div className={styles.labelText}>Mobile App</div>
              <span className={styles.checkmark}></span>
            </label>
            <label className={styles.labelCheckbox} htmlFor="erpSaas">
              <input
                className={styles.checkbox}
                type="checkbox"
                id="erpSaas"
                onChange={onCheckboxClick}
                checked={contactData.needs.erpSaas}
              />
              <div className={styles.labelText}>Decentralized App</div>
              <span className={styles.checkmark}></span>
            </label>
            <label className={styles.labelCheckbox} htmlFor="other">
              <input
                className={styles.checkbox}
                type="checkbox"
                id="other"
                onChange={onCheckboxClick}
                checked={contactData.needs.other}
              />
              <div className={styles.labelText}>Other</div>
              <span className={styles.checkmark}></span>
            </label>
          </div>
        </div>
        <div className={styles.formContainerRightFieldsMargin}>
          <div className={styles.formContainerRightFieldsMarginLabel}>Message</div>
          <textarea
            className={styles.textarea}
            id="message"
            placeholder="Additional information"
            autoComplete="off"
            onChange={onInputChange}
            value={contactData.message}
          ></textarea>
        </div>
        <div className={styles.formContainerRightButtonContainer}>
          <button
            onClick={formSubmit}
            className={cn(styles.button, {
              [styles.sendInProgressButton]: contactData.internalFormState.emailSendInProgress,
              [styles.inactiveButton]: contactData.internalFormState.emailAlreadySent,
            })}
            disabled={contactData.internalFormState.emailAlreadySent}
          >
            {contactData.internalFormState.emailAlreadySent ? "EMAIL RECEIVED, THANK YOU!" : "SUBMIT REQUEST"}
          </button>
        </div>
      </div>
    </CardContainer>
  );
};

export default ContactForm;
