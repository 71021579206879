import React from "react";
import styles from "./Dots.module.scss";
import cn from "utils/classNames";
import dotsLeft from "assets/svgs/dots-rectangle-left.svg";
import dotsRight from "assets/svgs/dots-rectangle-right.svg";
import { useEffect } from "react";
import gsap from "gsap/all";

export enum DotsHorizontalPosition {
  Left,
  Right,
}

export enum DotsVerticalPosition {
  Top,
  Bottom,
}

type Props = {
  horizontal: DotsHorizontalPosition;
  vertical: DotsVerticalPosition;
};

const DotsTopLeft: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({ horizontal, vertical, className }) => {
  return (
    <img
      src={horizontal === DotsHorizontalPosition.Left ? dotsLeft : dotsRight}
      alt=""
      className={cn(
        {
          [styles.dots]: true,
          [styles.dotsTopLeft]: vertical === DotsVerticalPosition.Top && horizontal === DotsHorizontalPosition.Left,
          [styles.dotsTopRight]: vertical === DotsVerticalPosition.Top && horizontal === DotsHorizontalPosition.Right,
          [styles.dotsBottomLeft]:
            vertical === DotsVerticalPosition.Bottom && horizontal === DotsHorizontalPosition.Left,
          [styles.dotsBottomRight]:
            horizontal === DotsHorizontalPosition.Right && horizontal === DotsHorizontalPosition.Right,
        },
        className,
      )}
    />
  );
};

export default DotsTopLeft;
