import React, { FunctionComponent, ReactNode } from "react";
import cn from "utils/classNames";
import CardContainer from "../CardContainer";
import { BackgroundGradient } from "shared/types";
import styles from "./CardFAQ.module.scss";

type Props = {
  text: string;
  icon: ReactNode;
  activeBackground: BackgroundGradient;
  small?: boolean;
  active?: boolean;
  onClick?: () => void;
};

const CardFAQ: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  text,
  icon,
  small,
  activeBackground,
  active,
  className,
  ...props
}) => {
  const activeContainerVariant = active ? cn(styles.cardFaqContainerActive, styles.animatedGradient) : "";
  const backgroundClass = active ? styles[`cardFaqContainer${activeBackground}`] : "";

  return (
    <CardContainer
      className={cn(styles.cardFaqCommon, className)}
      {...props}
    >
      <div className={cn(styles.cardFaqContainer, backgroundClass, activeContainerVariant)}>
        {icon}
        <div className={styles.cardFaqContainerText}>{text}</div>
      </div>
    </CardContainer>
  );
};

export default CardFAQ;
