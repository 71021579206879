import React, { FunctionComponent } from "react";
import { generateShortUUID } from "utils";
import styles from "./CardTeamMember.module.scss";

type Props = {
  resume?: string;
  photo: string;
};

const MemberPhoto: FunctionComponent<Props> = ({ resume, photo }) => {
  return (
    <div className={styles.cardTeamMemberPhotoContainer}>
      <div className={styles.cardTeamMemberPhotoContainerPhoto}>
        <img src={photo} alt="" />
      </div>
      {/* <div className={styles.cardTeamMemberPhotoContainerResume}>
        <a href={resume} download>
        <img src={ResumeIcon} alt=""></img>
        </a>
      </div> */}
    </div>
  );
};

export default MemberPhoto;
