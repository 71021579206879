import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ContactForm from "components/ContactForm";
import Dots, { DotsHorizontalPosition, DotsVerticalPosition } from "components/Dots/Dots";
import styles from "./Contact.module.scss";
import useViewport from "shared/hooks/useViewport";
import dotsMobile from "assets/svgs/dots-contact-mobile.svg";
import cn from "utils/classNames";

const Contact: React.FC = () => {
  const { width } = useViewport();
  gsap.registerPlugin(ScrollTrigger);

  const smallScreenDots = () => {
    return width <= 600;
  };

  useEffect(() => {
    setTimeout(() => {

      const contactDotsAnimation = gsap.from('.contactDots', {
        scrollTrigger: {
          trigger: ".contactDots",
          start: smallScreenDots() ? "bottom, 50%" : "center 80%",
        },
        duration: 0.3,
        opacity: 0,
        ...(smallScreenDots() ? { y: -120 } : { x: -144 }),
      });


      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: ".contactFormAnimation",
          start: "top 75%",
        },
        defaults: {
          duration: 0.3,
          opacity: 0,
        },
      });

      timeline
        .from(".contactTitleAnimation", {})
        .from(".contactFormAnimation", smallScreenDots() ? { x: 12 } : { x: 48 });
    }, 100);
  }, []);

  return (
    <div className={styles.contact} id="contact">
      <div className={cn("contactTitleAnimation", styles.title)}>Let's turn your concept into a real product!</div>
      <div className={styles.form}>
        <ContactForm className={cn("contactFormAnimation", styles.contactFormOverlay)} />
        {smallScreenDots() ? (
          // <Dots horizontal={DotsHorizontalPosition.Left} vertical={DotsVerticalPosition.Top} />
          <img className={cn("contactDots", styles.contactMobileDots)} src={dotsMobile} alt="" />
        ) : (
          <Dots
            className={cn("contactDots")}
            horizontal={DotsHorizontalPosition.Left}
            vertical={DotsVerticalPosition.Bottom}
          />
        )}
      </div>
    </div>
  );
};

export default Contact;
