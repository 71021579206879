import React from "react";
import styles from "./Hero.module.scss";
import ButtonPrimary from "components/Button";
import NavBar from "components/NavBar";
import Logo from "components/Logo";
import RevolvingSphere from "components/RevolvingSphere";
import useViewport from "shared/hooks/useViewport";

const Hero: React.FC = () => {
  const { width } = useViewport();

  const smallScreen = () => {
    return width <= 1200;
  };

  return (
    <div className={styles.heroContainer}>
      <div className={styles.content}>
        <div className={styles.contentNavbarContainer}>
          <Logo />
          <NavBar className={styles.contentNavbar} />
        </div>
        <div className={styles.contentMain}>
          <div className={styles.textContainer}>
            <div className={styles.title}>We talk product, not just code!</div>
            <div className={styles.description}>
              We are a digital agency specialized in providing <span>fast</span> and <span>scalable</span> solutions for
              your business to thrive.
            </div>
            <a href="#contact">
              <ButtonPrimary className={styles.contactBtn}>Contact us</ButtonPrimary>
            </a>
          </div>
          {smallScreen() ? null : (
            <div className={styles.sceneContainer}>
              <RevolvingSphere
                canvasId="scene"
                widthValue={400}
                heightValue={400}
                dotsAmmount={200}
                dotRadius={1}
                rotationSpeed={0.00015}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
