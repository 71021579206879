import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { SeparatorBackground } from "shared/types/enums";
import CardAbout from "components/Card/CardAbout/CardAbout";
import styles from "./WhatWeDo.module.scss";
import ContentSection from "components/ContentSection";
import wwdBackgroundDesktop from "assets/svgs/wwd-background-desktop.svg";
import wwdBackgroundMobile from "assets/svgs/wwd-background-mobile.svg";
import sectionComponent from "shared/hoc/sectionComponent";
import useViewport from "shared/hooks/useViewport";
import cn from "utils/classNames";

const WhatWeDo: React.FC = () => {
  const { width, breakpoints } = useViewport();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (width <= 1024) {
      ["aboutCardAnimaTopLeft", "aboutCardAnimaTopRight", "aboutCardAnimaBottomLeft", "aboutCardAnimaBottomRight"].map(
        (elem, idx, array) => {
          gsap.from(`.${elem}`, {
            scrollTrigger: {
              trigger: `.${elem}`,
              start: "top 90%",
            },
            duration: 0.8,
            opacity: 0,
            x: idx % 2 ? 60 : -60,
            delay: idx === array.length - 1 ? 0 : 0.2 * idx,
          });
        },
      );
    } else {
      var timeline = gsap.timeline({
        defaults: {
          duration: 0.8,
          opacity: 0,
        },
      });
      timeline
        .from(".aboutCardAnimaTopLeft", { scale: 1.2, transformOrigin: "center" })
        .from(".aboutCardAnimaTopRight", { scale: 1.2, transformOrigin: "center" }, "-=.6")
        .from(".aboutCardAnimaBottomRight", { scale: 1.2, transformOrigin: "center" }, "-=.6")
        .from(".aboutCardAnimaBottomLeft", { scale: 1.2, transformOrigin: "center" }, "-=.6");
    }
  }, []);

  return (
    <div className={styles.wwdContainer} id="whatwedo">
      <img
        className={styles.wwdContainerBackground}
        alt=""
        src={width <= breakpoints.md ? wwdBackgroundMobile : wwdBackgroundDesktop}
      />
      <ContentSection
        title="What we do"
        subtitle={
          <>
            We help our customers <span>envision</span>, <span>build</span> and <span>launch</span> digital solutions,
            with a primarily focus on developing MVPs for innovating & promising startups!
          </>
        }
        hasDiagonalDots={true}
      >
        <div className={styles.aboutCardsContainer}>
          <CardAbout
            className={cn("aboutCardAnimaTopLeft", styles.aboutCard)}
            title="Business & Product Development"
            separatorBackground={SeparatorBackground.GradientOrange}
          >
            We leverage our previous <span>startup</span> & <span>product design</span> experience to help you implement
            the <span>best launch plan</span>. We’re here to help your product <span>surpass</span> its industry
            competitors.
          </CardAbout>
          <CardAbout
            className={cn("aboutCardAnimaTopRight", styles.aboutCard)}
            title="Full-Stack web development"
            separatorBackground={SeparatorBackground.GradientMisc1}
          >
            Every web app we develop is <span>scalable</span>, <span>responsive</span>, and suitable for any device, no
            matter the browser or the screen size. The code is <span>clear</span>, <span>readable</span> and{" "}
            <span>avoidant of any future technical debt</span>.
          </CardAbout>
          <CardAbout
            className={cn("aboutCardAnimaBottomLeft", styles.aboutCard)}
            title="Mobile App development"
            separatorBackground={SeparatorBackground.GradientRed}
          >
            Let’s face it, the current market is highly competitive. Your app needs to look and work{" "}
            <span>flawlessly</span> to stand out. We transform your idea into an <span>eye-catching app</span> available
            on <span>Play Store</span> and/or <span>App Store</span>!
          </CardAbout>
          <CardAbout
            className={cn("aboutCardAnimaBottomRight", styles.aboutCard)}
            title="QA & App Testing"
            separatorBackground={SeparatorBackground.GradientMisc2}
          >
            This is a continuous process that begins on day one. Our QA team will perform <span>manual</span> and{" "}
            <span>automated tests</span>, to confirm that the app works flawlessly and ensure it is{" "}
            <span>bug-free</span>!
          </CardAbout>
        </div>
      </ContentSection>
    </div>
  );
};

export default sectionComponent(WhatWeDo);
