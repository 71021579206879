import React from "react";
import SkillsTree from "components/SkillsTree/SkillsTree";
import ContentSectionTitle, { TitleVariant } from "components/ContentSection/ContentSectionTitle";
import sectionComponent from "shared/hoc/sectionComponent";
import styles from "./Skills.module.scss";

const Skills: React.FC = () => {
  return (
    <>
      <ContentSectionTitle
        className={styles.skillsTitle}
        titleVariant={TitleVariant.Headline}
        title={
          <>
            We cover the whole development stack,
            <br /> so you won’t have to worry hiring anyone else
          </>
        }
        subtitle={
          <>
            We have you covered, from <span>designing</span> the UI of your application to <span>building</span> and{" "}
            <span>scaling</span> the infrastructure. Our follow-through method ensures you are satisfied and working
            with your ideal team. <br />
            <div style={{ marginTop: "18px", fontWeight: 600 }}>We only succeed if you do.</div>
          </>
        }
      />
      <SkillsTree />
    </>
  );
};

export default sectionComponent(Skills);
