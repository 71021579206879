import React, { useState } from "react";
import cn from "utils/classNames";
import FaqQuestion from "./FaqQuestion";
import { Question } from "shared/types";
import styles from "./FaqList.module.scss";
import FaqCardsRow from "./FaqCardsRow/FaqCardsRow";
import { generateShortUUID } from "utils";

type Props = {
  questions: Question[];
};

const FaqLine: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, id }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1080 2"
      height="2"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.6"
        y1="1.4"
        x2="1079.4"
        y2="1.39991"
        stroke={`url(#${id})`}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="4 6"
      />
      <defs>
        <linearGradient id={id} x1="0" y1="2" x2="1080" y2="2.00002" gradientUnits="userSpaceOnUse">
          <stop offset="0.0001" stop-color="#CED2DB" stop-opacity="0" />
          <stop offset="0.166667" stop-color="#CED2DB" />
          <stop offset="0.502659" stop-color="#CED2DB" />
          <stop offset="0.833233" stop-color="#CED2DB" />
          <stop offset="1" stop-color="#CED2DB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const FaqList: React.FC<Props> = ({ questions }) => {
  const [state, setState] = useState({
    activeSectionIndex: 0,
    animating: false,
  });
  const handleActiveCardChange = (index) => {
    const newState = { ...state, animating: true };
    setState(newState);
    setTimeout(() => {
      setState({
        ...newState,
        activeSectionIndex: index,
        animating: false,
      });
    }, 300);
  };
  return (
    <>
      <FaqCardsRow
        className={cn(styles.listSection, styles.cardsRow)}
        cards={questions.map(({ card }) => card)}
        activeCard={state.activeSectionIndex}
        onActiveCardIndexChange={(index) => handleActiveCardChange(index)}
      />
      <div
        className={cn(styles.questionsList, styles.listSection, {
          [styles.invisible]: state.animating,
          [styles.visible]: !state.animating,
        })}
      >
        {questions[state.activeSectionIndex].questions.map(({ question, children }, index) => (
          <>
            <FaqQuestion key={generateShortUUID()} question={question}>
              {children}
            </FaqQuestion>
            <FaqLine className={styles.lineSeparator} id={generateShortUUID()} />
          </>
        ))}
      </div>
    </>
  );
};

export default FaqList;
