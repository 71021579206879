import * as React from "react";
import Hero from "sections/Hero";
import WhatWeDo from "sections/WhatWeDo";
import Team from "sections/Team";
import Contact from "sections/Contact";
import Footer from "sections/Footer";
import dotsFaqLeft from "assets/svgs/faq-dots-left.svg";
import Faq from "sections/Faq/Faq";
import Skills from "sections/Skills";
import Interview from "pages/interview";
import "styles/index.scss";
import styles from "./Index.module.scss";

import { ViewportProvider } from "shared/context/ViewportContext";
import ChatBubble from "components/ChatBubble";
import Hotjar from "components/Hotjar";
import GoogleAnalytics from "components/GoogleAnalytics";
import Metadata from "components/Metadata";

const IndexPage = () => {
  return (
    <ViewportProvider>
      <Metadata />
      <Hotjar />
      <GoogleAnalytics />
      <ChatBubble />
      <div className={styles.sections}>
        <Hero />
        <WhatWeDo />
        <Skills className={styles.sectionsSkills} />
        <Team className={styles.sectionsTeam} />
        <Contact />
        <Faq className={styles.sectionsQuestions} />
        <div className={styles.sectionsFooter}>
          <img className={styles.sectionsFooterDots} src={dotsFaqLeft} alt="" />
          <Footer />
        </div>
      </div>
      {/* <Interview /> Uncomment this to see the page at the bottom of the current one */}
    </ViewportProvider>
  );
};

export default IndexPage;
