import React from "react";
import cn from "utils/classNames";
import styles from "./FaqCardsRow.module.scss";
import dotsFaq from "assets/svgs/dots-faq-right.svg";
import { QuestionCard } from "shared/types";
import CardFAQ from "components/Card/CardFAQ";
import { NOOP_FUNCTION } from "utils";
import { FaqCardsRowProps } from "./types";
import { useEffect } from "react";
import gsap from "gsap/all";

const FaqCardsRowDesktop: React.FC<FaqCardsRowProps> = ({
  cards = [],
  activeCard = 0,
  onCardSelected = NOOP_FUNCTION,
  className,
}) => {
  useEffect(() => {
    gsap.to(".faqDesktopRowDots", {
      scrollTrigger: {
        trigger: ".faqDesktopRow",
        start: "-20% center",
        end: "+=300",
        scrub: 1,
      },
      y: 20,
      opacity: 0.6,
    });
  });

  return (
    <div className={cn("faqDesktopRow", styles.desktopRow, className)}>
      <img src={dotsFaq} alt="" className={cn("faqDesktopRowDots", styles.desktopRowDots)} />
      {cards.map((card: QuestionCard, index) => (
        <CardFAQ
          className={styles.desktopRowCard}
          key={index}
          {...card}
          active={activeCard === index}
          onClick={() => onCardSelected(index)}
        />
      ))}
    </div>
  );
};

export default FaqCardsRowDesktop;
