import { BackgroundGradient, TeamMember } from "shared/types";
import ImgVlad from "assets/images/team-members/vlad.png";
import ImgCristi from "assets/images/team-members/cristi.png";
import ImgKaran from "assets/images/team-members/karan.png";
import ImgAna from "assets/images/team-members/ana.png";
import ImgEmi from "assets/images/team-members/emi.png";
import ImgFlorin from "assets/images/team-members/florin.png";
import ImgRobert from "assets/images/team-members/robert.png";
import ImgIulian from "assets/images/team-members/iulian.png";
import ImgJoshua from "assets/images/team-members/joshua.png";
import ImgTaylor from "assets/images/team-members/taylor.png";
import ImgDenis from "assets/images/team-members/denis.jpg";
import { generateShortUUID } from "utils";

const resume = null;
const teamMembers: TeamMember[] = [
  {
    id: generateShortUUID(),
    name: "Vlad Tărniceru",
    position: "Chief Executive Officer",
    experience: "7+ years",
    resume: resume,
    photo: ImgVlad,
    skills: [
      { name: "product", background: BackgroundGradient.Gradient2 },
      { name: "Front-End", background: BackgroundGradient.Gradient3 },
      { name: "blockchain", background: BackgroundGradient.Gradient9 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      x: -48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Emanuel Muraru",
    position: "Chief Technical Officer",
    experience: "6+ years",
    resume: resume,
    photo: ImgEmi,
    skills: [
      { name: "back-end", background: BackgroundGradient.Gradient4 },
      { name: "devops", background: BackgroundGradient.Gradient6 },
      { name: "blockchain", background: BackgroundGradient.Gradient9 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      y: -48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Karan Aggarwal",
    position: "Chief Operating Officer",
    experience: "7+ years",
    resume: resume,
    photo: ImgKaran,
    skills: [
      { name: "Marketing", background: BackgroundGradient.Gradient1 },
      { name: "Product", background: BackgroundGradient.Gradient2 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      x: 48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Ana Georgescu",
    position: "Business Development Director",
    experience: "5+ years",
    resume: resume,
    photo: ImgAna,
    skills: [
      { name: "Marketing", background: BackgroundGradient.Gradient1 },
      { name: "Product", background: BackgroundGradient.Gradient2 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      x: 48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Denis Mită",
    position: "Technical Lead",
    experience: "7+ years",
    resume: resume,
    photo: ImgDenis,
    skills: [
      { name: "Product", background: BackgroundGradient.Gradient2 },
      { name: "front-end", background: BackgroundGradient.Gradient3 },
      { name: "back-end", background: BackgroundGradient.Gradient4 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      x: -48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Cristian Conache",
    position: "Software Engineer",
    experience: "6+ years",
    resume: resume,
    photo: ImgCristi,
    skills: [
      { name: "front-end", background: BackgroundGradient.Gradient3 },
      { name: "back-end", background: BackgroundGradient.Gradient4 },
      { name: "blockchain", background: BackgroundGradient.Gradient9 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1,
      x: 48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Florin Georgescu",
    position: "Solution Architect",
    experience: "9+ years",
    resume: resume,
    photo: ImgFlorin,
    skills: [
      { name: "front-end", background: BackgroundGradient.Gradient3 },
      { name: "back-end", background: BackgroundGradient.Gradient4 },
      { name: "devops", background: BackgroundGradient.Gradient6 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      x: -48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Iulian Munteanu",
    position: "Software Engineer",
    experience: "3+ years",
    resume: resume,
    photo: ImgIulian,
    skills: [
      { name: "front-end", background: BackgroundGradient.Gradient3 },
      { name: "back-end", background: BackgroundGradient.Gradient4 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      y: 48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Taylor Hughes",
    position: "Marketing Specialist",
    experience: "2+ years",
    resume: resume,
    photo: ImgTaylor,
    skills: [
      { name: "Marketing", background: BackgroundGradient.Gradient1 },
      { name: "Product", background: BackgroundGradient.Gradient2 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      x: 48,
    },
  },
  {
    id: generateShortUUID(),
    name: "Robert Ciocîrlan",
    position: "Software Engineer",
    experience: "3+ years",
    resume: resume,
    photo: ImgRobert,
    skills: [
      { name: "front-end", background: BackgroundGradient.Gradient3 },
      { name: "back-end", background: BackgroundGradient.Gradient4 },
    ],
    entryAnimation: {
      opacity: 0,
      duration: 1.2,
      x: -48,
    },
  }
];

export default teamMembers;
