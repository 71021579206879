import React, { useEffect } from 'react';

const ChatBubble: React.FC = () => {
    useEffect(() => {
        if (process.env.GATSBY_TAWK_ENABLED === 'false') {
            return null;
        }
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function() {
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/60f72a7d649e0a0a5ccd2774/1fb2nrunb';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
      }, []);

    return (
        <></>
    )
}

export default ChatBubble;
