import React, { ReactNode, useState } from "react";
import cn from "utils/classNames";
import styles from "./FaqQuestion.module.scss";
import plus from "assets/svgs/plus.svg";
import minus from "assets/svgs/minus.svg";
import useViewport from "shared/hooks/useViewport";

type Props = {
  question: string;
  children: ReactNode;
};

const FaqQuestion: React.FC<Props> = ({ question, children }) => {
  const { width, breakpoints } = useViewport();
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.question} onClick={() => setOpen(!open)}>
      <div className={styles.questionHeadline}>
        <div className={styles.title}>
          {width <= breakpoints.sm ? <span>&bull;&nbsp;</span> : null}
          {question}
        </div>
        <img className={styles.actionBtn} alt="" src={open ? minus : plus} onClick={() => setOpen(!open)} />
      </div>
      <div
        className={cn(styles.answer, { [styles.answerExpanded]: open })}
        style={{ maxHeight: open ? "300px" : "0px" }}
      >
        {children}
      </div>
    </div>
  );
};

export default FaqQuestion;
