import React, { useEffect, useState } from "react";
import Loadable from "@loadable/component";
import cn from "utils/classNames";
import { NOOP_FUNCTION } from "utils";
import useViewport from "shared/hooks/useViewport";
import { QuestionCard } from "shared/types";
import FaqCardsRowDesktop from "./FaqCardsRowDesktop";

const FaqCardsRowMobile = Loadable(() => import("./FaqCardsRowMobile"));

export type Props = {
  cards: QuestionCard[];
  activeCard?: number;
  onActiveCardIndexChange?: (index: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const FaqCardsRow: React.FC<Props> = ({
  cards = [],
  activeCard = 0,
  onActiveCardIndexChange = NOOP_FUNCTION,
  className,
}) => {
  const [activeCardIndex, setActiveCardIndex] = useState(activeCard);

  useEffect(() => {
    onActiveCardIndexChange(activeCardIndex);
  }, [activeCardIndex]);

  const { width } = useViewport();
  const mobileView = width <= 860;

  return (
    <>
      {mobileView && (
        <FaqCardsRowMobile
          className={cn(className)}
          cards={cards}
          activeCard={activeCardIndex}
          onCardSelected={(index) => setActiveCardIndex(index)}
        />
      )}
      {!mobileView && (
        <FaqCardsRowDesktop
          className={cn(className)}
          cards={cards}
          activeCard={activeCardIndex}
          onCardSelected={(index) => setActiveCardIndex(index)}
        />
      )}
    </>
  );
};

export default FaqCardsRow;
