import React from "react";
import { BackgroundGradient, Question } from "shared/types";
import Partnership from "assets/svgs/partnership.component.svg";
import Price from "assets/svgs/price.component.svg";
import Technology from "assets/svgs/technology.component.svg";
import Team from "assets/svgs/team.component.svg";

const questionsList: Question[] = [
  {
    card: {
      text: "Partnership",
      icon: <Partnership />,
      activeBackground: BackgroundGradient.Gradient1,
    },
    questions: [
      {
        question: "Will you only help us by delivering code?",
        children: (
          <>
            No, not necessarily, and that decision is entirely up to you. Although we like writing code, what we love is
            actually building products. More than half of our team has first-hand experience working for multiple
            startups.
            <br />
            We understand the initial difficulties of having a startup, and we specialize in multiple areas to ensure we
            are able to provide help and assistance.
          </>
        ),
      },
      {
        question: "What if I don’t have a designer and/or mockups ready?",
        children: (
          <>
            We have in-house product and UX/UI designers as part of our team, who previously conceived multiple SaaS
            products, for both Desktop & Mobile, from scratch. This includes experts in both B2B and B2C environments
            who are ready to help.
          </>
        ),
      },
      {
        question: "What will your support be after you finish the project?",
        children: (
          <>
            We are in this for the long run! After the project is completed, we continue to offer full guidance and
            support, which includes a hyper-care period.
          </>
        ),
      },
    ],
  },
  {
    card: {
      text: "Pricing",
      icon: <Price />,
      activeBackground: BackgroundGradient.Gradient6,
    },
    questions: [
      {
        question: "How does pricing usually work for a project?",
        children: (
          <>
            It all comes down to the complexity and delivery time of each project. We will figure out the full
            requirements with you over a call and will be able to provide a quote accordingly.
          </>
        ),
      },
      {
        question: "How does your pricing compare to other competitors?",
        children: (
          <>
            We are absolutely sure we deliver the best quality-price ratio in the market. Our pricing is primarily based
            on the superior work we offer and swift delivery time.
            <br /> Moreover, our cloud expertise will ensure the performance and reliability of your product, while
            keeping the infrastructure costs as low as possible, which saves you money along the way.
          </>
        ),
      },
    ],
  },
  {
    card: {
      text: "Team",
      icon: <Team />,
      activeBackground: BackgroundGradient.Gradient2,
    },
    questions: [
      {
        question: "What is the Minerva Gate difference?",
        children: (
          <>
            We brought together a team of expert developers, to deliver the best products to our customers and live up
            to our mission. With a vast expertise, ranging from product design to cloud infrastructure, we pushed
            ourselves to understand the whole ecosystem, so at the end of the day we build products, not just code.
          </>
        ),
      },
      {
        question: "What is your previous experience with developing products and MVPs?",
        children: (
          <>
            Plenty! Our team members previously worked full-time with multiple startups, filling in key roles such as
            full-stack development, UX/UI design, and product design.
          </>
        ),
      },
      {
        question: "What is your developers’ previous work experience?        ",
        children: (
          <>Our engineers have very diverse backgrounds, working for Google, IBM, Keysight, Accenture, and UiPath.</>
        ),
      },
    ],
  },
  {
    card: {
      text: "Technologies",
      icon: <Technology />,
      activeBackground: BackgroundGradient.Gradient4,
    },
    questions: [
      {
        question: "What is your expertise?",
        children: (
          <>
            Everything web & mobile, we can deliver it! Our expertise includes such a wide variety of skills, that no
            task is off-limits.
          </>
        ),
      },
      {
        question: "What if the technology I need is not listed on your website?",
        children: (
          <>
            We can quickly ramp up on new technologies, so if you’re looking for something you don’t necessarily see
            just reach out to us! We want to use the technologies that are best for your product and not necessarily
            just the ones we’ve used before. As mentioned before, we are generalists by nature.
          </>
        ),
      },
    ],
  },
];

export default questionsList;
