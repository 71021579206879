import React, { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import cn from "utils/classNames";
import styles from "./Team.module.scss";
import CardTeamMember from "components/Card/CardTeam/CardTeamMember";
import sectionComponent from "shared/hoc/sectionComponent";
import ContentSectionTitle from "components/ContentSection/ContentSectionTitle";
import useViewport from "shared/hooks/useViewport";
import Dots, { DotsHorizontalPosition, DotsVerticalPosition } from "components/Dots/Dots";
import membersList from "./MembersList";
import { TeamMember } from "shared/types";

const componentBreakpoints = {
  lg: 1660,
  md: 1300,
  sm: 860,
};

const Team: React.FC = () => {
  const { width } = useViewport();
  const [membersRows, setMembersRows] = useState([]);

  const getItemsDistribution = () => {
    if (width <= componentBreakpoints.sm) {
      return membersList.map((_) => 1);
    }

    if (width <= componentBreakpoints.md) {
      return [2, 1, 2, 2, 1, 2];
    }

    return [3, 2, 3, 2];
  };

  const addGsapAnimations = () => {
    gsap.to(".topLeft", {
      scrollTrigger: {
        trigger: ".teamCardsContainer",
        start: "-200px center",
        end: "50%",
        scrub: 1,
      },
      y: -90,
      opacity: 0,
    });
    gsap.to(".bottomRight", {
      scrollTrigger: {
        trigger: ".teamCardsContainer",
        start: "center center",
        end: "bottom",
        scrub: 1,
      },
      y: 90,
      opacity: 1,
    });
  };

  useEffect(() => {
    const membersRows = getItemsDistribution().reduce(
      (acc, membersCountPerRow) => {
        const newAccIndex = acc.tmIndex + membersCountPerRow;
        const membersComponentsOnRow = membersList.slice(acc.tmIndex, newAccIndex).map((teamMember: TeamMember) => {
          return <CardTeamMember key={teamMember.id} {...teamMember} />;
        });
        acc.rows.push(membersComponentsOnRow);
        acc.tmIndex = newAccIndex;
        return acc;
      },
      { rows: [], tmIndex: 0 },
    ).rows;
    setMembersRows(membersRows);
    setTimeout(() => addGsapAnimations(), 500);
  }, [width]);

  return (
    <>
      <ContentSectionTitle
        className={styles.sectionTitle}
        title="Meet the Team"
        id="team"
        subtitle={
          <>
            <span>Ex-FAANG</span> with a proven experience of working together. We work responsively to create a flowing
            progression where each team member offers their expertise to get the perfect end result.
          </>
        }
      />
      <div className={styles.teamSectionContent}>
        <div className={cn("teamCardsContainer", styles.teamCardsContainer)}>
          <Dots
            className={cn("topLeft", styles.dotsTopLeft)}
            horizontal={DotsHorizontalPosition.Left}
            vertical={DotsVerticalPosition.Top}
          />
          <div className={styles.teamCardsLinesContainer}>
            {membersRows.map((row, index) => {
              return (
                <div className={styles.teamCardsLine}>
                  {row}
                  {/* TODO: fix this hardcoded stuff */}
                  {((index === 2 && width > componentBreakpoints.md) ||
                    (index === 3 && width < componentBreakpoints.md && width > componentBreakpoints.sm)) && (
                    <Dots
                      className={cn("bottomRight", styles.dotsBottomRight)}
                      horizontal={DotsHorizontalPosition.Right}
                      vertical={DotsVerticalPosition.Bottom}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default sectionComponent(Team);
