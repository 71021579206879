import React from "react";

const sectionComponent = (Component) => {
  const WrapperComponent: React.FC<React.HTMLAttributes<HTMLDivElement> & any> = ({ className, ...props }) => {
    return (
      <div className={className}>
        <Component {...props} />
      </div>
    );
  };

  return WrapperComponent;
};

export default sectionComponent;
