import React from "react";
import desktopBackground from "assets/svgs/faq-background-desktop.svg";
import mobileBackground from "assets/svgs/faq-background-mobile.svg";
import FaqList from "components/FaqList";
import styles from "./Faq.module.scss";
import QuestionsList from "./QuestionsList";
import sectionComponent from "shared/hoc/sectionComponent";
import ContentSectionTitle from "components/ContentSection/ContentSectionTitle";
import useViewport from "shared/hooks/useViewport";

const Faq: React.FC = () => {
  const { width, breakpoints } = useViewport();

  return (
    <div className={styles.questions} id="questions">
      <ContentSectionTitle
        className={styles.titleSection}
        title="Have more questions?"
        subtitle="We've made a list of the most frequent ones below."
      />
      <FaqList questions={QuestionsList} />
      <img
        className={styles.questionsBackground}
        alt=""
        src={width <= breakpoints.sm ? mobileBackground : desktopBackground}
      />
    </div>
  );
};

export default sectionComponent(Faq);
